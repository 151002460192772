import { PaymentContainer } from 'containers';
import { MainLayout } from 'layouts';
import { isMobile } from 'react-device-detect';

type Props = {};

export default function PaymentPage({}: Props) {
  return (
    <MainLayout isStepHeader step={2} isHideFooter haveBottomSpace={!isMobile}>
      <PaymentContainer />
    </MainLayout>
  );
}
